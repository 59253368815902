<template>
<v-container>
  <v-row> 
    <v-col cols="12">
<div class="about"  style="text-align: center">
    <h1>Контакты</h1>

  </div>
    </v-col>
    <v-col cols="12" sm="12" md="4" v-for="(n,i) in contact" :key="i">
      <v-btn
          :key="n.name_contact"
          class="mx-4"
          style="color: #2c1213; background-color:#e6b006;"
          :title="n.name_contact"
          :href="n.link+n.data_contact"
        >
          <v-icon size="24px">
            {{ n.icon_contact }}
          </v-icon>
          {{n.data_contact}}
        </v-btn>
    </v-col>
  </v-row>
</v-container>
  
</template>
<script>
export default {
  name: "App",

  data: () => ({
    items: [],
  }),
  computed: {
    contact() {
      return this.$store.getters.getContact;
    }
  },
  mounted() {
  this.$store.dispatch("getContactSite");
  }
};
</script>